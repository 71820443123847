.formData { padding:10px; display:flex; flex-direction:column; height:100%; box-sizing:border-box; justify-content:flex-start; align-items:stretch; align-content:stretch;

    .subheadline { text-align:center; margin:-20px 0 20px; font-style:italic; white-space:pre-line;
        p { margin-bottom:0; }
    }

    .questions { flex:1 0 auto; display:flex; flex-direction:column; justify-content:center; }

    .question { display:flex; flex-direction:column; margin-bottom:10px;
        .top { display:flex; flex-direction:row; justify-content:center; text-transform:uppercase; font-family:'Merriweather Sans', serif; font-weight:400;
            span { color:#ccc; margin-left:10px; }
        }

        .slider { display:flex; flex-direction:row; justify-content:center; margin:10px 0 20px;
            .cellDiv { padding-top:42px; width:46px; margin:0 5px; position:relative; border-radius:100%; border:2px solid #ccc; transition:all 200ms; cursor:pointer; box-sizing:border-box;
                .jeton { position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); width:48px; border-radius:100%; box-sizing:border-box; cursor:pointer; box-shadow:0 2px 5px rgba(0,0,0,.5); margin:auto auto; display:block; background:#fff; z-index:30; }
                .index { position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); text-align:center; color:#ccc; pointer-events:none; z-index:1; }

                &.zero { cursor:auto; }
                &.notAvailable { background:#ccc; opacity:.5;
                    .index {
                        text { fill:#fff; }
                    }
                }

                &.background_hover_add,
                &.background_hover:hover { background:rgba(0,0,0, .2);
                    svg text { fill:#fff; }
                }
            }

            &.slider100 {
                .cellDiv { padding-top:42px; width:46px; margin:0 0 0 5px; box-sizing:border-box;
                    &.small { width:12px; border:0; position:relative; background:none;
                        &:before { content:''; position:absolute; top:50%; left:50%; width:10px; height:10px; border-radius:100%; border:2px solid #ccc; transform:translate(-50%, -50%); background:#fff; z-index:20; }
                        &:after { content:''; position:absolute; top:50%; left:-5px; right:-5px; border-bottom:2px solid #ccc; transform:translateY(-50%); z-index:10; }
                        .index { display:none; }

                        &.highlight { background:none !important; }

                        &.notAvailable {
                            &:before { background:#ccc; }
                        }
                    }

                    &.background_hover_add,
                    &.background_hover:hover,
                    &.background_hover_before:hover:before { background:#ccc;
                        svg text { fill:#fff; }
                    }
                }

                .value { width:60px; text-align:center; line-height:40px; color:#ccc; }
            }
        }
    }
    .total { text-align:right; display:flex; justify-content:center; margin-bottom:40px;
        .val { border-top:4px double #aaa; text-align:center; line-height:2rem; padding:0 20px; color:#aaa; }
    }
}
