.table { width:50%; height:50%; transition:all 400ms; position:absolute; background-size:100% 100%; background-repeat:no-repeat; background-position:top left;

    &.top {
        .plus { top:auto; bottom:15%; transform:translate(-86%, -11.8%); }
        .minus { top:auto; bottom:15%; transform:translate(-14%, -11.8%); }
    }

    .plus { position:absolute; left:84.5%; top:21%; transform:translate(-86%, -19.8%); fill:rgba(255,255,255,.3); width:20%; height:20%; }
    .minus { position:absolute; left:15%; top:21%; transform:translate(-14%, -19.8%); fill:rgba(255,255,255,.3); width:20%; height:20%; }

    &.big { width:100%; height:100%; z-index:20;
        .pop {
            .percentage {
                text { font-size:10px; }
            }
        }
    }
    &.small { opacity:0; z-index:1; }

    table { width:100%; height:100%; border-collapse:collapse;
        tr { order:2;
            &.description { order:1;
                td { height:28.7%; border:1px solid rgba(255,255,255, .3); text-align:center; }
            }

            &.meaning {
                .pop.moreThan { background:rgba(51, 51, 51, .6); border-color:rgba(51, 51, 51, .7); }
            }
            &.time {
                .pop.moreThan { background:rgba(255,255,255,.6); border-color:rgba(255,255,255,.7); }
            }

            .pop { border:1px solid rgba(255,255,255, .3); box-sizing:border-box; min-height:9.09%; max-height:9.09%; position:relative; transition:all 200ms; cursor:pointer;
                &.disabled { width:2%;
                    //&.grey { background:rgba(255,255,255,.2); }
                }
                &.notAvailable { background:rgba(255,255,255,.2); }

                .percentage { position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); color:#fff; pointer-events:none; font-size:.9rem; }
                .jeton { width:192%; z-index:10; position:absolute; top:0; left:0; transform:translate(-25%, 0); display:block; margin:0; padding:0; }

                &[elm="0"] { border-left:0;
                    .percentage { left:30%; }
                }
                &[elm="100"] { border-right:0; margin:0; padding:0;
                    .percentage { left:70%; }
                }

                .percentage {
                    text { font-size:6px; transition:all 200ms; }
                }

                &.background_hover_add,
                &.background_hover:hover { background:rgba(0,0,0, .2); }
            }

            .jeton { width:96%; border-radius:100%; box-sizing:border-box; cursor:pointer; box-shadow:0 2px 5px rgba(0,0,0,.5); margin:auto auto; display:block; background:#fff; }

            .timeMeaning { padding:0; position:relative; box-sizing:border-box; background:rgba(0,0,0, .2); border:0;
                &:before { content:''; position:absolute; top:50%; right:0; left:0; border-bottom:1px solid rgba(255,255,255, .3); }
                &.disabled { opacity:.5; }

                table { width:100%; height:100%; position:relative; table-layout:fixed; border-collapse:collapse;
                    tr {
                        &:first-child {
                            td { border-top:0; }
                        }
                        &:last-child {
                            td { border-bottom:0;  }
                        }

                        td { border-top:0; border-bottom:0;
                            &:first-child { border-right:0; }
                            &:last-child { border:0; border-right:1px solid rgba(255,255,255,.3); }
                        }
                    }
                }
            }

        }
    }
}


@media (max-width:1024px) {
    table {
        tr {
            //&.description {
            //    td { font-size:.5rem; }
            //}
            //.jeton { font-size:.5rem; }
        }
    }
}
