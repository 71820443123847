.forms { width:50%; height:100%; position:relative; display:flex; flex-direction:column; justify-content:flex-start; align-items:stretch; align-content:stretch; overflow:hidden;
    .navigation { display:flex; flex-direction:row; list-style-type:none; margin:0; padding:0; justify-content:center; align-items:stretch; /*z-index:10;*/
        li { margin:0; display:block; text-align:center; line-height:4rem; position:relative; flex:1 0 auto;
            &.link {
                &:before { content:''; position:absolute; bottom:0; left:50%; width:0; border-bottom:3px solid #ccc; transform:translateX(-50%); transition:width 200ms; }
            }
            &.err {
                a { color:#a60000; }
            }

            &:hover:not(.disabled),
            &.current { font-weight:bold;
                &:before { width:100%; }
            }

            &.disabled:hover a { color:#333 !important; cursor:auto; }

            a,
            span { display:block; padding:0 5px; text-decoration:none; color:#333; white-space:nowrap; font-family:'Merriweather Sans', serif; font-size:1rem; transition:all 200ms;
                svg { width:20px; position:relative; top:2px; }
            }

            &.menu { position:relative; margin:0 0 0 10px; cursor:pointer;
                &:hover {
                    ul { display:block; }
                }

                svg { height:20px; cursor:pointer; margin:0 5px; position:relative; top:4px;
                    path { transition:all 200ms; }
                }
                ul { display:none; position:absolute; top:90%; right:0; background:#fff; box-shadow:0 0 5px rgba(0,0,0, .5); padding:10px; border-radius:5px; z-index:100;
                    li { line-height:2.5rem; text-align:right;
                        a { cursor:pointer; }
                    }
                }
            }

            &.social { padding:0 15px; }
        }
    }

    .content { flex:1 1 0; overflow-y:auto; }

    .horizontalShadow { width:100%; height:0; position:relative; z-index:1;
        &:before { content:''; display:block; width:100%; height:15px; position:absolute; top:0; right:0; left:0; background:url(../img/shadow.png) top center no-repeat; background-size:90% 15px; }
    }

    .footer { display:flex; flex-direction:row; justify-content:space-between; padding:20px 40px;
        .balanceAktiveLogo { height:60px; width:15%; max-width:200px; background-size:contain; background-repeat:no-repeat; background-position:left center; margin:auto 0; text-indent:-9999px; }
        .logo { height:60px; width:15%; max-width:200px; margin:auto 0;
            span { width:100%; height:100%; display:block; background-size:contain; background-repeat:no-repeat; background-position:right center;  }
        }

        .centerBox { margin:auto; width:70%; display:flex; flex-direction:row; justify-content:center;
            a,
            div { background:#646464; color:#fff; text-decoration:none; line-height:2.5rem; display:inline-block; padding:0 20px 0 30px; margin:1px; border-radius:20px 0 0 20px; text-transform:uppercase; width:80px; text-align:center;  font-family:'Merriweather Sans', serif; transition:all 200ms; white-space:nowrap;
                &.next { border-radius:0 20px 20px 0; padding:0 30px 0 20px; }
                &.disabled { background:none; }
                &.disable { background:#ccc; cursor:not-allowed; }

                &.centerButton {
                    padding:0 20px; /*border-radius:0;*/ width:auto;
                }
            }
        }
    }

}


@media (max-width:1024px) {
    .forms { width:100%; border-bottom:1px solid #ccc;
        ul { position:sticky; top:0; background:#fff; border-bottom:1px solid #ccc; }
    }
}
