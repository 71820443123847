@import "font";
@import "text";

html { font-size:14px; }
body, html { height:100%; }
body { background:#fff; font:normal 14px/1.4em; font-weight:300; color:#191919; position:relative; overflow-x:hidden;
    &.noscroll { overflow:hidden; }
}

input, textarea { font:normal 14px/1.4em; color:#191919; }

input { box-shadow: none !important; }
:-webkit-autofill { color: #fff !important; }
textarea:focus, input:focus{
    outline: none;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; } /* FF 4-18 */
input:focus::-moz-placeholder { color:transparent; } /* FF 19+ */
input:focus:-ms-input-placeholder { color:transparent; } /* IE 10+ */


textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder { color: #c8c8c8; text-align:center; }

textarea:-moz-placeholder,
input:-moz-placeholder { color:#c8c8c8; text-align:center; } /* FF 4-18 */

textarea::-moz-placeholder,
input::-moz-placeholder { color:#c8c8c8; text-align:center; } /* FF 19+ */

textarea:-ms-input-placeholder,
input:-ms-input-placeholder { color:#c8c8c8; text-align:center; } /* IE 10+ */

a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

strong { font-weight:400; }

h1 { font-size:4.0625rem; line-height:4.5625rem; font-weight:700; margin:0; text-transform:uppercase; }
h2 { font-size:3rem; line-height:3.5rem; font-weight:700; margin:0; text-transform:uppercase; }
h3 { font-size:1.75rem; line-height:2.25rem; font-weight:300; margin:0; text-transform:uppercase; }
h4 { font-size:1.25rem; line-height:1.75rem; font-weight:700; margin:0; text-transform:uppercase; }
h5 { font-size:1rem; line-height:1.5rem; font-weight:700; margin:0; }
p { font-size:1rem; line-height:1.5rem; font-style:italic; }

.button { width:50%; margin:auto; display:block; border-radius:20px; line-height:2rem; color:#fff; cursor:pointer; border:1px solid transparent; transition:200ms all; background:none;
    &:disabled { background:#ddd !important; cursor:not-allowed; }
}

@keyframes sk-bouncedelay {
    0%, 80%, 100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    } 40% {
          -webkit-transform: scale(1.0);
          transform: scale(1.0);
      }
}


#root { padding:0; height:100%;

}

@media (max-width:1440px) {
    html { font-size:13px; }
}

@media (max-width:800px) {
    html { font-size:14px; }
    body { overflow:auto; position:relative; }
}

@media (max-width:600px) {
    html { font-size:12px; }
}
