.overlay { position:fixed; top:0; right:0; bottom:0; left:0; background:rgba(0,0,0,.4); z-index:100;

    .login { position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); background:#fff; box-shadow:0 0 10px 0 rgba(0,0,0,.3); border-radius:6px; overflow:hidden;
        ul { display:flex; flex-direction:row; padding:0; margin:0; list-style-type:none;
            li { flex:1; text-align:center; line-height:3rem; color:#fff; background:#ccc; text-transform:uppercase; cursor:pointer;
                &.current { background:#fff; }
            }
        }

        form { padding:30px 40px; width:500px;
            p { text-align:center; font-style:italic; padding:0 0 30px; white-space:pre-line; }

            .box { margin-bottom:10px;
                input { width:100%; border:0; border-bottom:1px solid #ccc; box-sizing:border-box; line-height:2rem; text-align:center; padding:0 100px; text-overflow:ellipsis; }

                &.copy { display:flex; flex-direction:row;
                    svg { width:25px; height:25px; margin-left:15px; }
                    input[disabled] { background:none; }
                    input.copied { border-color:#6b9e0b; }
                    svg.check { fill:#6b9e0b; }

                    button { display:flex; flex-direction:row; border:0; padding:0; background:none; width:100%; }
                }
            }

            .error { text-align:center; margin-top:20px; color:#a60000; }
            .ok { text-align:center; margin-top:20px; color:#6b9e0b; }

            .bottomBox { margin:30px 0 0; display:flex; flex-direction:row; justify-content:space-between;
                button { width:40%; margin:auto; display:block; border:0; border-radius:20px; line-height:2rem; color:#fff; cursor:pointer; }

                .space { width:120px; }
                .logo { width:120px; height:40px; background-repeat:no-repeat; background-position:right center; background-size:contain; }
            }
        }
    }

    .logout { position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); background:#fff; box-shadow:0 0 10px 0 rgba(0,0,0,.3); border-radius:6px; overflow:hidden; padding:30px 40px; width:500px; box-sizing:border-box;
        p { text-align:center; font-style:italic; white-space:pre-line; }

        .bottomBox { margin:30px 0 0; display:flex; flex-direction:row; justify-content:space-between;
            .button { width:40%; margin:auto; display:block; border:0; border-radius:20px; line-height:2rem; color:#fff; cursor:pointer; text-align:center; }
        }
    }
}

@media (max-width:1023px) {
    .overlay { display:none; }
}
