@font-face {
    font-family: 'icomoon';
    src:  url('../fonts/prowandel.eot?1');
    src:  url('../fonts/prowandel.eot?1#iefix') format('embedded-opentype'),
    url('../fonts/prowandel.ttf?1') format('truetype'),
    url('../fonts/prowandel.woff?1') format('woff'),
    url('../fonts/prowandel.svg?1#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"]:before, [class*=" icon-"]:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    position:relative;
    top:1px;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-anerkennung:before {
    content: "\e900";
}
.icon-bedeutung:before {
    content: "\e901";
}
.icon-beruf:before {
    content: "\e902";
}
.icon-entspannung:before {
    content: "\e903";
}
.icon-erfolg:before {
    content: "\e904";
}
.icon-erfuellung:before {
    content: "\e905";
}
.icon-erholung:before {
    content: "\e906";
}
.icon-ernaehrung:before {
    content: "\e907";
}
.icon-familie:before {
    content: "\e908";
}
.icon-fitness:before {
    content: "\e909";
}
.icon-freude:before {
    content: "\e90a";
}
.icon-geborgenheit:before {
    content: "\e90b";
}
.icon-geld:before {
    content: "\e90c";
}
.icon-gesundheit:before {
    content: "\e90d";
}
.icon-karriere:before {
    content: "\e90e";
}
.icon-kontakte:before {
    content: "\e90f";
}
.icon-lebenserwartung:before {
    content: "\e910";
}
.icon-liebe:before {
    content: "\e911";
}
.icon-selbswert:before {
    content: "\e912";
}
.icon-sicherheit:before {
    content: "\e913";
}
.icon-verwirklichung:before {
    content: "\e914";
}
.icon-werte:before {
    content: "\e915";
}
.icon-zeit:before {
    content: "\e916";
}
.icon-zukunft:before {
    content: "\e917";
}
.icon-zuwendung:before {
    content: "\e918";
}
