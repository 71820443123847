.cell { border:1px solid rgba(255,255,255, .3); box-sizing:border-box; width:9.09%; height:9.09%; max-height:9.09%; position:relative; transition:all 200ms; cursor:pointer;
    &.zero { background:rgba(0,0,0, .1); cursor:auto; }
    &.background_hover_add,
    &.background_hover:hover { background:rgba(0,0,0, .1); }

    .tooltip { display:none; position:absolute; left:100%; top:50%; transform:translateY(-50%); z-index:10; background:rgba(0,0,0,.5); font-size:.9rem; line-height:1.5rem; color:#fff; max-width:150px; width:150px; padding:10px 20px; pointer-events:none;
        &:before { content:''; position:absolute; top:50%; right:100%; transform:translateY(-50%); width:0; height:0; border-style:solid; border-width:10px 15px 10px 0; border-color:transparent rgba(0,0,0,.5) transparent transparent; }

        &.left { left:auto; right:100%;
            &:before { right:auto; left:100%; border-width:10px 0 10px 15px; border-color: transparent transparent transparent rgba(0,0,0,.5); }
        }
    }

    .jeton:not(.onDrag):hover + .tooltip { display:block; }
}

.table:not(.big).gGesundheit,
.table:not(.big).gArbeit {
    .cell {
        .tooltip {
            &[dataquestion="0"] { top:2px; transform:translateY(0);
                &:before { top:5px; transform:translateY(0); }
            }
        }
    }
}

.table:not(.big).gSoziale-Beziehungen,
.table:not(.big).gSinn {
    .cell {
        .tooltip {
            &[dataquestion="5"] { top:auto; bottom:2px; transform:translateY(0);
                &:before { top:auto; bottom:5px; transform:translateY(0); }
            }
        }
    }
}
