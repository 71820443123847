.gameField { position:relative; width:100%; padding-top:100%; margin:auto; box-sizing:border-box;
    .gameFieldBox { position:absolute; top:0; right:0; bottom:0; left:0; display:flex; flex-wrap:wrap;
        .center { position:absolute; top:50%; left:50%; width:20vh; transform:translate(-50%, -50%); opacity:1; transition:all 400ms;
            &.small { opacity:0; }
        }
    }


    .my-node-enter {
        opacity: 0;
    }
    .my-node-enter-active {
        opacity: 1;
        transition: opacity 200ms;
    }
    .my-node-exit {
        opacity: 1;
    }
    .my-node-exit-done {
        opacity:0;
    }
    .my-node-exit-active {
        opacity: 0;
        transition: opacity 200ms;
    }
}
