.infoBubbleBox { display:block; position:fixed; top:0; right:0; bottom:0; left:0; z-index:900; background:rgba(0,0,0,.5); filter:blur(0) !important;
    .infoBubble { margin:auto; z-index:9999; width:90%; max-width:600px; height:80%; max-height:400px; position:fixed; top:50%; left:50%; transform:translate(-50%, -50%); border-radius:25px; color:#fff; font-family:'Merriweather Sans', serif !important; box-shadow:0 0 10px 0 rgba(0,0,0,.3);
        &:before { content:''; display:block; position:absolute; top:100%; left:5%; width:0; height:0; border-style:solid; border-width:50px 50px 0 0; border-color:#fff transparent transparent transparent; }

        .infoBubbleInner { width:100%; height:100%; overflow:hidden; position:relative; box-sizing:border-box; padding:40px 40px; display:flex; flex-direction:column; border-radius:25px;
            .close { fill:#fff; width:25px; height:25px; position:absolute; top:30px; right:30px; cursor:pointer; padding:10px; border-radius:5px; transition:background-color 200ms;
                &:hover { background:rgba(255,255,255,.5);  }
            }

            h3 { font-style:normal; }
            .infoText { display:flex; flex-direction:column; height:100%; justify-content:center;
                p { margin:0; font-style:normal; }
            }

            .infobubble { fill:#fff; opacity:.1; width:400px; height:400px; position:absolute; right:-15%; bottom:-20%;
                path { width:100%; height:100%; }
            }
        }
    }
}
