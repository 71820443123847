.overlay { position:fixed; top:0; right:0; bottom:0; left:0; background:rgba(255,255,255,.8); z-index:1000;
    .overlayBox { position:absolute; top:50%; left:50%; transform:translate(-50%, -50%); background:#fff; box-shadow:0 0 10px 0 rgba(0,0,0,.3); border-radius:6px; z-index:1; }
}

.modal { cursor:pointer; z-index:1000 !important;
    .overlayBox { max-width:500px; width:100%; padding:20px; box-sizing:border-box; cursor:auto;
        .modal_buttons { display:grid; grid-template-columns:1fr 1fr; column-gap:20px; margin-top:20px;
            .modal_button { margin:0; width:100%; }
        }
    }
}
